import React from "react";
import "./App.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function App() {
  return (
    <div className="App">
      <div className="App-header">
      <div className="App-text">{`info@ergoquod.com`} </div>
         <div className="logo-image"></div>

         
      </div>
      
    </div>
  );
}

export default App;
